

import TextField from '@mui/material/TextField';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom'; // Make sure RouterLink is imported
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, loginUser } from '../../actions/userAction';
import { useSnackbar } from 'notistack';
import BackdropLoader from '../Layouts/BackdropLoader';
import MetaData from '../Layouts/MetaData';
import i1 from '../../../src/assets/images/Banners/logindesign1.png';
//import TnC from '../../components/TnC';

const logimgage = [i1];

const Login = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const location = useLocation();

    const { loading, isAuthenticated, error } = useSelector((state) => state.user);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = (e) => {
        e.preventDefault();
        dispatch(loginUser(email, password));
    }

    const redirect = location.search ? location.search.split("=")[1] : "/";

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isAuthenticated) {
            navigate(`${redirect}`);
        }
    }, [dispatch, error, isAuthenticated, redirect, navigate, enqueueSnackbar]);

    return (
        <>
            <MetaData title="Login | Grafin" />

            {loading && <BackdropLoader />}
            <main className="w-full mt-12 sm:pt-20 sm:mt-0">

                {/* <!-- row --> */}
                <div className="flex flex-col sm:flex-row sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white shadow-lg">

  {/* Login column - appears first on mobile and second on desktop */}
  <div className="flex-1 overflow-hidden order-1 sm:order-2">
    <div className="text-center py-10 px-4 sm:px-14">
      <form onSubmit={handleLogin}>
        <div className="flex flex-col w-full gap-4">
          <TextField
            fullWidth
            id="email"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <TextField
            fullWidth
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <div className="flex flex-col gap-2.5 mt-2 mb-4 sm:mb-32">
            <p className="text-xs text-primary-grey text-left">
              By continuing, you agree to Grafin's 
              <RouterLink to="/TnC" className="text-primary-blue">Terms & Conditions</RouterLink>
              and 
              <RouterLink to="/PrivacyPolicy" className="text-primary-blue">Privacy Policy.</RouterLink>
            </p>
            <button
              type="submit"
              className="text-white py-3 w-full bg-primary-orange shadow hover:shadow-lg rounded-sm font-medium"
              style={{ backgroundColor: '#df52dd' }}
            >
              Login
            </button>
            <RouterLink
              to="/password/forgot"
              className="hover:bg-gray-50 text-primary-blue text-center py-3 w-full shadow border rounded-sm font-medium"
            >
              Forgot Password?
            </RouterLink>
          </div>
        </div>
      </form>
      <RouterLink to="/register" className="font-medium text-sm text-primary-blue">
        New to Grafin? Create an account
      </RouterLink>
    </div>
  </div>

  {/* Image column - appears second on mobile and first on desktop */}
  <div className="w-full sm:w-1/2 order-2 sm:order-1">
    <img src={logimgage[0]} alt="logindesign" className="w-full h-auto" />
  </div>

</div>

                {/* <!-- row --> */}

            </main>
        </>
    );
};

export default Login;
