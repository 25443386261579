import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useSnackbar } from 'notistack';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearErrors, registerUser } from '../../actions/userAction';
import BackdropLoader from '../Layouts/BackdropLoader';
import MetaData from '../Layouts/MetaData';
import i1 from '../../../src/assets/images/Banners/logindesign1.png';

const logimgage = [i1];

const Register = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    

    const { enqueueSnackbar } = useSnackbar();

    const { loading, isAuthenticated, error } = useSelector((state) => state.user);

    const [user, setUser] = useState({
        name: "",
        email: "",
        gender: "",
        password: "",
        cpassword: "",
    
    });

    

    const { name, email, gender, password, cpassword } = user;

    const handleRegister = (e) => {
        e.preventDefault();
        if (password.length < 8) {
            enqueueSnackbar("Password length must be at least 8 characters", { variant: "warning" });
            return;
        }
        if (password !== cpassword) {
            enqueueSnackbar("Password doesn't match", { variant: "error" });
            return;
        }

        const formData = new FormData();
        formData.set("name", name);
        formData.set("email", email);
        formData.set("gender", gender);
        formData.set("password", password);
        

        dispatch(registerUser(formData));
    };

    const handleDataChange = (e) => {
        
            setUser({ ...user, [e.target.name]: e.target.value });
        }


    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: "error" });
            dispatch(clearErrors());
        }
        if (isAuthenticated) {
            navigate('/');
        }
    }, [dispatch, error, isAuthenticated, navigate, enqueueSnackbar]);

    return (
        <>
            <MetaData title="Register | Grafin" />

            {loading && <BackdropLoader />}
            <main className="w-full mt-12 sm:pt-20 sm:mt-0">
            <div className="flex flex-col sm:flex-row sm:w-4/6 sm:mt-4 m-auto mb-7 bg-white shadow-lg">
  {/* Image column - appears first on desktop and second on mobile */}
  <div className="w-full sm:w-1/2 order-2 sm:order-1">
    <img src={logimgage[0]} alt="logindesign" className="w-full h-auto" />
  </div>

  {/* Form column - appears first on mobile and second on desktop */}
  <div className="flex-1 overflow-hidden order-1 sm:order-2">
    <form
      onSubmit={handleRegister}
      className="p-5 sm:p-10"
      encType="multipart/form-data"
    >
      <div className="flex flex-col gap-4 items-start">
        <div className="flex flex-col w-full justify-between sm:flex-col gap-3 items-center">
          <TextField
            fullWidth
            id="full-name"
            label="Full Name"
            name="name"
            value={name}
            onChange={handleDataChange}
            required
          />
          <TextField
            fullWidth
            id="email"
            label="Email"
            type="email"
            name="email"
            value={email}
            onChange={handleDataChange}
            required
          />
        </div>
        <div className="flex gap-4 items-center">
          <h2 className="text-md">Your Gender :</h2>
          <div className="flex items-center gap-6" id="radioInput">
            <RadioGroup
              row
              aria-labelledby="radio-buttons-group-label"
              name="gender"
              value={gender}
              onChange={handleDataChange}
            >
              <FormControlLabel
                value="male"
                control={<Radio required />}
                label="Male"
              />
              <FormControlLabel
                value="female"
                control={<Radio required />}
                label="Female"
              />
            </RadioGroup>
          </div>
        </div>
        <div className="flex flex-col w-full justify-between sm:flex-row gap-3 items-center">
          <TextField
            id="password"
            label="Password"
            type="password"
            name="password"
            value={password}
            onChange={handleDataChange}
            required
          />
          <TextField
            id="confirm-password"
            label="Confirm Password"
            type="password"
            name="cpassword"
            value={cpassword}
            onChange={handleDataChange}
            required
          />
        </div>

        <button type="submit" className="text-white py-3 w-full bg-primary-orange shadow hover:shadow-lg rounded-sm font-medium">Signup</button>
        <Link to="/login" className="hover:bg-gray-50 text-primary-blue text-center py-3 w-full shadow border rounded-sm font-medium">Existing User? Log in</Link>
        <Link to="/selleranddeliveryguyregister" className="hover:bg-gray-50 text-primary-blue text-center py-3 w-full shadow border rounded-sm font-medium">For a business purpose</Link>
      </div>
    </form>
  </div>
</div>

            </main>
        </>
    );
};

export default Register;
