import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import cfood from '../../assets/images/Categories/cfood.png';
import fashion from '../../assets/images/Categories/cfashion.png';
import celectronics from '../../assets/images/Categories/celectronics.png';
import chome from '../../assets/images/Categories/chome.png';
import celectrical from '../../assets/images/Categories/celectrical.png';
import cmobilepart from '../../assets/images/Categories/cmobilepart.png';
import cgrosery from '../../assets/images/Categories/cgrosery.png';
import copticals from '../../assets/images/Categories/copticals.png';
import ctoys from '../../assets/images/Categories/ctoys.png';
import cveg from '../../assets/images/Categories/cveg.png';
import cmobileaccessory from '../../assets/images/Categories/cmobileaccessory.png';
import cgatgets from '../../assets/images/Categories/cgatgets.png';
import '../../../src/components/Layouts/Categories.css';

import { Pagination } from 'swiper/modules';
const catNav = [
  { name: "Electronics", icon: celectronics },
  { name: "Mobile Accessories", icon: cmobileaccessory },
  { name: "Food", icon: cfood },
  { name: "Vegetables", icon: cveg },
  { name: "Toys", icon: ctoys },
  { name: "Fashion", icon: fashion },
  { name: "Home Appliances", icon: chome },
  { name: "Electrical", icon: celectrical },
  { name: "Groceries", icon: cgrosery },
  { name: "Opticals", icon: copticals },

];
console.log("catNav",catNav);


const Categories = () => {



  return (

    <div className='responsive-container'>
      <Swiper
        slidesPerView={7} // Default on desktop
        spaceBetween={5} // Reduced space between slides
        pagination={{ clickable: true }}
        navigation={false}
        modules={[Pagination, Autoplay]}
        breakpoints={{
          // Extra small devices (phones)
          320: {
            slidesPerView: 3,
            spaceBetween: 5, // Reduced space for smaller screens
            autoplay: {
              delay: 2500,
              disableOnInteraction: false,
            },
          },
          // Small devices (tablets)
          768: {
            slidesPerView: 4, // More slides to fit tablet view
            spaceBetween: 8, // Reduced space for cleaner look
            autoplay: {
              delay: 2500,
              disableOnInteraction: false,
            },
          },
          // Medium devices (desktops)
          1024: {
            slidesPerView: 7, // Adjusted to 5 for better visibility
            spaceBetween: 5, // Minimal space
            autoplay: false,
          },
          // Large devices (large desktops)
          1440: {
            slidesPerView: 8,
            spaceBetween: 10, // Slightly larger space for balance
            autoplay: false,
          },
          // 4K devices (large screens)
          1920: {
            slidesPerView: 10,
            spaceBetween: 5, // Small space on larger screens
            autoplay: false,
          },
        }}
      >


        {catNav.map((item, i) => (

          <SwiperSlide key={item.id} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Link to={`/products?category=${item.name}`} className="flex flex-col gap-1 items-center p-2 group">
              <img
                src={item.icon}
                alt={item.name}
                style={{
                  width: '80%',
                  height: '80%',
                  borderRadius: '50%',
                  transition: 'all 0.3s ease-in-out', // Smooth transition for effects
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.boxShadow = '0 8px 20px rgba(0, 0, 0, 0.4)';
                  e.currentTarget.style.transform = 'scale(1.1)';
                  e.currentTarget.style.filter = 'brightness(.9)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.boxShadow = 'none';
                  e.currentTarget.style.transform = 'scale(1)';
                  e.currentTarget.style.filter = 'brightness(1)';
                }}
              />

              <span style={{ fontSize: '14px', textAlign: 'center' }}>
                {item.name}
              </span>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>


  );
};

export default Categories;
