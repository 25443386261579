import { getDiscount } from '../../../utils/functions';
import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, removeFromWishlist } from '../../../actions/wishlistAction';
import { useSnackbar } from 'notistack';

const Product = (props) => {

    const { _id, name, images, ratings, numOfReviews, price, cuttedPrice } = props;

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const { wishlistItems } = useSelector((state) => state.wishlist);
    
    const itemInWishlist = wishlistItems.some((i) => i.product === _id);

    const addToWishlistHandler = () => {
        if(itemInWishlist) {
            dispatch(removeFromWishlist(_id));
            enqueueSnackbar("Remove From Wishlist", { variant: "success" });
        } else {
            dispatch(addToWishlist(_id));
            enqueueSnackbar("Added To Wishlist", { variant: "success" });
        }
    }

    return (
    
        <div className="flex flex-col items-center gap-2 px-6 py-8 m-4 bg-white relative group rounded-lg shadow-md">
    {/* <!-- image & product title --> */}
    <Link to={`/product/${_id}`} className="flex flex-col items-center text-center">
        <div className="w-36 h-36 relative">
            {/* First Image */}
            <img
                draggable="false"
                className="w-full h-full object-contain transition-transform duration-150 ease-out group-hover:scale-110"
                src={images[0].url}
                alt={name}
            />
            {/* Second Image (Visible on Hover) */}
            <img
                draggable="false"
                className="w-full h-full object-contain absolute inset-0 opacity-0 transition-opacity duration-300 group-hover:opacity-100"
                src={images[1]?.url || images[0].url} // Fallback to the first image if a second image isn't available
                alt={name}
            />
        </div>
        <h2 className="text-sm mt-4 group-hover:text-primary-blue">
            {name.length > 50 ? `${name.substring(0, 50)}...` : name}
        </h2>
    </Link>
    {/* <!-- image & product title --> */}

    {/* <!-- product description --> */}
    <div className="flex flex-col gap-2 items-center">
        {/* <!-- rating badge --> */}
        <span className="text-sm text-gray-500 font-medium flex gap-2 items-center">
            <span className="text-xs px-1.5 py-0.5 bg-primary-green rounded-sm text-white flex items-center gap-0.5">
                {ratings.toFixed(1)} <StarIcon sx={{ fontSize: "14px" }} />
            </span>
            <span>({numOfReviews.toLocaleString()})</span>
        </span>
        {/* <!-- rating badge --> */}

        {/* <!-- price container --> */}
        <div className="flex items-center gap-1.5 text-md font-medium">
            <span>₹{price.toLocaleString()}</span>
            <span className="text-gray-500 line-through text-xs">₹{cuttedPrice.toLocaleString()}</span>
            <span className="text-xs text-primary-green">{getDiscount(price, cuttedPrice)}%&nbsp;off</span>
        </div>
        {/* <!-- price container --> */}
    </div>
    {/* <!-- product description --> */}

    {/* <!-- wishlist badge --> */}
    <span
        onClick={addToWishlistHandler}
        className={`${itemInWishlist ? "text-red-500" : "hover:text-red-500 text-gray-300"} absolute top-5 right-2 cursor-pointer`}
    >
        <FavoriteIcon sx={{ fontSize: "16px" }} />
    </span>
    {/* <!-- wishlist badge --> */}
</div>

    
    
    );
};

export default Product;
