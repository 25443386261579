
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useNavigate, Link } from 'react-router-dom';
import { getProducts, clearErrors } from '../../actions/productAction';
import Loader from '../Layouts/Loader';
import Product from './Product';
import Pagination from '@mui/material/Pagination';
import MetaData from '../Layouts/MetaData';
import { useSnackbar } from 'notistack';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import MinCategory from '../Layouts/MinCategory';

// Categories array
export const catNav = [
    { name: "Electronics" },
    { name: "Mobile Accessories" },
    { name: "Food" },
    { name: "Vegetables" },
    { name: "Groceries" },
    { name: "Fashion" },
    { name: "Home Appliances" },
    { name: "Opticals" },
    { name: "Toys" },
    { name: "Electrical" },


];

// Subcategories mapping for each category
const subcategoryMapping = {
    "Electronics": [
        "Television",
        "Mobile",
        "Laptops & computers",
        "Printers & scanners",
        "Camera",
        "Home theater",
        "Air Conditioner",
        "Washing Machine",
        "Freezers",

    ],

    "Mobile Accessories": [

        "Mobile Covers & Holders",
        "Charger & Cable",
        "Smart Watches",
        "Headsets & earbuds",
        "Power Banks",
        "Pendrive & Memorey Cards",
        "Bluetooth devices",


    ],
    "Food": [
        "Breakfast",
        "Lunch",
        "dinner",
        "Snacks",
        "cooldrinks"],

    "Vegetables": [
        "Vegetables",
        "Fruits"
    ],
    "Groceries": [
        "Rice",
        "Flour",
        "Dairy Products",
        "tea & coffee powder ",
        "Masala & Spices",
        "Oil",
        "Dry Fruits",
        "Nuts",
        "Bakery Products",
        "Baby Care",
        "Packaged Snacks",
        "Home Cleaner",
    ],
    "Fashion": [
        "Boys t-shirts & pants",
        "Girls t-shirts & pants",
        "Mens Accessories",
        "Womens Accessories",
        "Kids Accessories",
        "Athletic Wear",
        "Foot Wear",
        "Sportshoes & sneackers",
        "Spring & summer",
        "Tops & dresses",
        "Jwellery",
        "Handbags",
        "Watches"
    ],
    "Home Appliances": [
        "Kitchen & dining",
        "Furniture",
        "Weather Control Appliances",
        " Electrical & Lighting",
        "Personal Care Appliances",
        "Water purification",
        "Cleaning Appliances",
        "Home Security Appliances",


    ],

    "Opticals": [
        "Eyewear",
        "Contact Lenses",
        "lens Care Products",
        "Optical Accessories",
        "Optical Instruments",
        "Vision Care Products",
        "Optical Devices",
        "Optometric Tools",
    ],

    "Toys": [
        "Trycycles",
        "Boost & plasters",
        "Card & Boardgames",
        "Action Figure",
        "Dolls",
        "Animal Toys",
        "Educational Toys",
        "Outdoor games",
        "Puzzles",
        "Remote Control Toys",
        "Musical Toys",
        "Sensore Toys",
    ],
    "Electrical": [

        "Lighting",
        "Wire",
        "Cutting Plier",
        "screw driver kit",
        "Wire Box",

    ],



};

// Define subcategory menu items
const getSubcategoryMenu = (subcategories, handleSubcategoryClick) => {
    return subcategories.map((subcat, i) => (
        <Link
            key={i}
            to="#"
            className="flex items-center justify-between p-2 bold-text text-black rounded-md shadow-sm hover:bg-[#cbacbe] cursor-pointer"
            onClick={() => handleSubcategoryClick(subcat)}
        >
            <span>{subcat}</span>
            <ArrowForwardIos fontSize="small" className="ml-2 text-white" /> {/* Using small size */}
        </Link>
    ));
};

const Products = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();

    const [price, setPrice] = useState([0, 200000]);
    const [category, setCategory] = useState(location.search ? location.search.split('=')[1] : "");
    const [ratings, setRatings] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [availability, setAvailability] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [hoveredCategory, setHoveredCategory] = useState(null); // Track hovered category
    const [subcategories, setSubcategories] = useState([]); // Store related subcategories
    const [selectedSubcategory, setSelectedSubcategory] = useState(null); // Selected subcategory
    const [isSubcategoryMenuOpen, setIsSubcategoryMenuOpen] = useState(false);
    
    const [name, setName] = useState('');

    //     // Update the state based on the URL query
       const values = new URLSearchParams(location.search).get('category');
         console.log("name",values);
    
    const toggleSubcategoryMenu = () => {
        setIsSubcategoryMenuOpen(prev => !prev);
    };
    const { products, loading, error, productsCount, resultPerPage, filteredProductsCount } = useSelector((state) => state.products);
    const keyword = params.keyword;

    const handleCategoryHover = (cat) => {
        setHoveredCategory(cat);
        setSubcategories(subcategoryMapping[cat] || []); // Get subcategories for the hovered category
    };

    const handleCategoryClick = (cat) => {
        if (hoveredCategory === cat) {

            setHoveredCategory(null);
            setSubcategories([]);
        } else {

            setHoveredCategory(cat);
            setSubcategories(subcategoryMapping[cat] || []);
        }
        setCategory(cat);
        navigate(`/products?category=${cat}`);
        setSelectedSubcategory(null);
    };


    const handleSubcategoryClick = (subcat) => {
        setSelectedSubcategory(subcat);

        dispatch(getProducts(keyword, category, price, ratings, currentPage, discount, availability, subcat));
    };

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
            dispatch(clearErrors());
        }
        dispatch(getProducts(keyword, category, price, ratings, currentPage, discount, availability, selectedSubcategory));
        

    }, [dispatch, keyword, category, price, ratings, currentPage, discount, availability, error, enqueueSnackbar, selectedSubcategory]);

    return (
        <>
            <MetaData title="All Products | Grafin" />
            <section
                className="bg-white mt-10 mb-4 min-w-full px-4 sm:px-12 py-1 shadow overflow-hidden"
                style={{ overflowX: window.innerWidth < 640 ? 'auto' : 'hidden' }}
            >
                <div
                    className="flex items-center justify-between mt-4"
                    style={{
                        display: 'flex',
                        overflowX: window.innerWidth < 640 ? 'auto' : 'hidden',
                        whiteSpace: 'nowrap'
                    }}
                >
                    {catNav.map((item, i) => (
                        <div
                            key={i}
                            className="flex flex-col gap-1 items-center p-2 group cursor-pointer"
                            onClick={() => handleCategoryClick(item.name)}
                            style={{
                                display: 'inline-block', // Ensure items are inline
                                marginRight: '8px' // Optional: add some spacing between items
                            }}
                        >
                            <span className="text-sm text-gray-800 font-medium group-hover:text-primary-blue">
                                {item.name}
                            </span>
                        </div>
                    ))}
                </div>
            </section>


            {/* Categories navigation */}



            <main className="w-full mt-5 sm:mt-0 flex flex-col sm:flex-row">
                {subcategories.length > 0 && (
                    <aside className="hidden sm:block w-full md:w-[20%] lg:w-[15%] xl:w-[10%] p-4 shadow-lg mb-4" style={{ backgroundColor: "#f1d3e4", height: "100vh" }}>
                        <h3 className="text-lg font-semibold mb-4 p-2 rounded-md" style={{ backgroundColor: "#834467d4", color: "#e3ded0" }}>Subcategories</h3>
                        <nav className="flex flex-col gap-2">
                            {subcategories.map((subcategory, index) => (
                                <button key={index} className="p-2 hover:bg-[#cbacbe] text-left" onClick={() => handleSubcategoryClick(subcategory)}>
                                    {subcategory}
                                </button>
                            ))}
                        </nav>
                    </aside>
                )}

                {/* Sidebar for mobile */}
                {(hoveredCategory && subcategories.length > 0 && (isSubcategoryMenuOpen || window.innerWidth < 640)) && (
                    <aside className="sm:hidden w-full p-4 shadow-lg mb-4" style={{ backgroundColor: "#f1d3e4" }}>
                        <h3 className="text-lg font-semibold mb-4 p-2 rounded-md" style={{ backgroundColor: "#834467d4", color: "#e3ded0" }}>Subcategories</h3>
                        <nav className="flex flex-col gap-2 bold-text">
                            {getSubcategoryMenu(subcategories, handleSubcategoryClick)}
                        </nav>
                    </aside>
                )}

                {/* Main content: Products */}
                <div className={`flex-1 ${hoveredCategory || isSubcategoryMenuOpen ? 'ml-0 sm:ml-4' : ''}`}>
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            {/* No results found */}
                            {products?.length === 0 && (
                                <div className="flex flex-col items-center justify-center gap-3 bg-white shadow-sm rounded-sm p-6 sm:p-16">
                                    <img
                                        draggable="false"
                                        className="w-1/2 h-44 object-contain"
                                        src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/error-no-search-results_2353c5.png"
                                        alt="Search Not Found"
                                    />
                                    <h1 className="text-2xl font-medium text-gray-900">Sorry, no results found!</h1>
                                    <p className="text-xl text-center text-primary-grey">
                                        Please check the spelling or try searching for something else
                                    </p>
                                </div>
                            )}

                            {/* Products grid */}
                            <div className="flex flex-col gap-2 pb-4 justify-center items-center w-full overflow-hidden bg-white">
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 w-full place-content-start overflow-hidden pb-4 border-b">
                                    {products?.map((product) => (
                                        <Product {...product} key={product._id} />
                                    ))}
                                </div>

                                {/* Pagination if products exceed result per page */}
                                {filteredProductsCount > resultPerPage && (
                                    <Pagination
                                        count={Number(((filteredProductsCount + 6) / resultPerPage).toFixed())}
                                        page={currentPage}
                                        onChange={(e, val) => setCurrentPage(val)}
                                        color="primary"
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </main>
        </>
    );
};

export default Products;
