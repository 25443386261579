

import React, { useEffect, useRef } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import ChatIcon from '@mui/icons-material/Chat';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { logoutUser } from '../../../actions/userAction';

const PrimaryDropDownMenu = ({ setTogglePrimaryDropDown, setToggleMobileMenu, user }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { wishlistItems } = useSelector((state) => state.wishlist);

    const dropdownRef = useRef(null);

    const handleLogout = () => {
        dispatch(logoutUser());
        navigate("/login");
        enqueueSnackbar("Logout Successfully", { variant: "success" });
        setTogglePrimaryDropDown(false);
        setToggleMobileMenu(false); // Close the mobile menu as well
    }

    const handleLinkClick = () => {
        setTogglePrimaryDropDown(false);
        setToggleMobileMenu(false); // Close the mobile menu as well
    }

    
    const navs = [
        {
            title: "Orders",
            icon: <ShoppingBagIcon sx={{ fontSize: "15px" }} />,
            redirect: "/orders",
        },
        {
            title: "My Chats",
            icon: <ChatIcon sx={{ fontSize: "15px" }} />,
            redirect: "/",
        },
        {
            title: "Coupons",
            icon: <ConfirmationNumberIcon sx={{ fontSize: "15px" }} />,
            redirect: "/",
        },
        {
            title: "Gift Cards",
            icon: <AccountBalanceWalletIcon sx={{ fontSize: "15px" }} />,
            redirect: "/",
        },
        {
            title: "Notifications",
            icon: <NotificationsIcon sx={{ fontSize: "15px" }} />,
            redirect: "/",
        },
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setTogglePrimaryDropDown(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [setTogglePrimaryDropDown]);

    return (
        <div
            ref={dropdownRef}
            className="absolute bg-white shadow-2xl rounded flex-col text-sm z-50"
            style={{
                top: '50px',
                right: '10px',
                width: '160px',
                ...(window.innerWidth <= 640 && {
                    position: 'fixed',
                    top: '90px',
                    left: '10px',
                    right: '6px',
                    width: 'calc(48% - 10px)',
                    boxShadow: '0px 5px 15px rgba(0,0,0,0.3)',
                }),
            }}
        >
            {user.role === "admin" && (
                <Link className="pl-3 py-1.5 border-b flex gap-3 items-center hover:bg-gray-50 rounded-t" to="/admin/dashboard" onClick={handleLinkClick}>
                    <span className="text-primary-blue">
                        <DashboardIcon sx={{ fontSize: "15px" }} />
                    </span>
                    Admin Dashboard
                </Link>
            )}
            <Link className="pl-3 py-1.5 border-b flex gap-3 items-center hover:bg-gray-50" to="/account" onClick={handleLinkClick}>
                <span className="text-primary-blue">
                    <AccountCircleIcon sx={{ fontSize: "18px" }} />
                </span>
                My Profile
            </Link>
            {navs.map((item, i) => {
                const { title, icon, redirect } = item;
                return (
                    <Link
                        className="pl-3 py-1.5 border-b flex gap-3 items-center hover:bg-gray-50"
                        to={redirect}
                        key={i}
                        onClick={handleLinkClick}
                    >
                        <span className="text-primary-blue">{icon}</span>
                        {title}
                        {title === "Wishlist" && (
                            <span className="ml-auto mr-3 bg-gray-100 p-0.5 px-2 text-gray-600 rounded">
                                {wishlistItems.length}
                            </span>
                        )}
                    </Link>
                );
            })}
            <div
                className="pl-3 py-1.5 flex gap-3 items-center hover:bg-gray-50 rounded-b cursor-pointer"
                onClick={handleLogout}
            >
                <span className="text-primary-blue">
                    <PowerSettingsNewIcon sx={{ fontSize: "18px" }} />
                </span>
                Logout
            </div>
        </div>
    );
};

export default PrimaryDropDownMenu;

