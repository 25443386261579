import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import MetaData from '../Layouts/MetaData';
import CartItem from './CartItem';
import EmptyCart from './EmptyCart';
import PriceSidebar from './PriceSidebar';
import SaveForLaterItem from './SaveForLaterItem';

const Cart = () => {
    const navigate = useNavigate();
    const { cartItems } = useSelector((state) => state.cart);
    const { saveForLaterItems } = useSelector((state) => state.saveForLater);

    const placeOrderHandler = () => {
        navigate('/shipping');
    }

    return (
        <>
        <MetaData title="Shopping Cart | Grafin" />
        <main className="w-full mt-20">
            <div className="flex flex-col gap-5 sm:flex-row sm:w-11/12 sm:mx-auto sm:mb-7">

                {/* Cart Items */}
                <div className="flex-1">
                    {/* Cart Items Container */}
                    <div className="flex flex-col shadow bg-white">
                        <span className="font-medium text-lg px-4 py-4 border-b sm:px-8">My Cart ({cartItems.length})</span>

                        {/* Empty Cart Component */}
                        {cartItems.length === 0 && <EmptyCart />}

                        {/* Cart Items List */}
                        {cartItems.map(item => (
                            <CartItem {...item} inCart={true} key={item.product} />
                        ))}

                        {/* Place Order Button */}
                        <div className="flex justify-end">
                            <button
                                onClick={placeOrderHandler}
                                disabled={cartItems.length < 1}
                                className={`w-full sm:w-auto mx-2 sm:mx-6 my-4 py-3 font-medium text-white shadow rounded-sm ${cartItems.length < 1 ? "bg-primary-grey cursor-not-allowed" : "hover:bg-primary-orange-dark"}`}
                                style={{ backgroundColor: '#427ef5',width:'150px' }}
                            >
                                PLACE ORDER
                            </button>
                        </div>
                    </div>

                    {/* Saved for Later Items Container */}
                    <div className="flex flex-col mt-5 shadow bg-white">
                        <span className="font-medium text-lg px-4 py-4 border-b sm:px-8">Saved For Later ({saveForLaterItems.length})</span>
                        {saveForLaterItems.map(item => (
                            <SaveForLaterItem {...item} key={item.product} />
                        ))}
                    </div>
                </div>

                {/* Price Sidebar */}
                <PriceSidebar cartItems={cartItems} />
            </div>
        </main>
    </>
    );
};

export default Cart;
