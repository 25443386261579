import axios from "axios";
import {
    ALL_PRODUCTS_FAIL,
    ALL_PRODUCTS_REQUEST,
    ALL_PRODUCTS_SUCCESS,
    PRODUCT_DETAILS_REQUEST,
    PRODUCT_DETAILS_SUCCESS,
    PRODUCT_DETAILS_FAIL,
    ADMIN_PRODUCTS_REQUEST,
    ADMIN_PRODUCTS_SUCCESS,
    ADMIN_PRODUCTS_FAIL,
    CLEAR_ERRORS,
    NEW_REVIEW_REQUEST,
    NEW_REVIEW_SUCCESS,
    NEW_REVIEW_FAIL,
    NEW_PRODUCT_REQUEST,
    NEW_PRODUCT_SUCCESS,
    NEW_PRODUCT_FAIL,
    UPDATE_PRODUCT_REQUEST,
    UPDATE_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    DELETE_PRODUCT_REQUEST,
    DELETE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_FAIL,
    ALL_REVIEWS_REQUEST,
    ALL_REVIEWS_SUCCESS,
    ALL_REVIEWS_FAIL,
    DELETE_REVIEW_REQUEST,
    DELETE_REVIEW_SUCCESS,
    DELETE_REVIEW_FAIL,
    SLIDER_PRODUCTS_REQUEST,
    SLIDER_PRODUCTS_SUCCESS,
    SLIDER_PRODUCTS_FAIL,
    PRODUCT_SUGGESTIONS_REQUEST,
    PRODUCT_SUGGESTIONS_SUCCESS,
    PRODUCT_SUGGESTIONS_FAIL,
    SUBCATEGORIES_REQUEST, SUBCATEGORIES_SUCCESS, SUBCATEGORIES_FAIL,
} from "../constants/productConstants";

// Get All Products --- Filter/Search/Sort
export const getProducts = (keyword = "", category, price = [0, 200000], ratings = 0, currentPage = 1, discount = 0, availability = "", subcategory = "") => async (dispatch) => {
    try {
        dispatch({ type: ALL_PRODUCTS_REQUEST });

        let url = `/api/v1/products?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}`;
        
        if (category) {
            url += `&category=${category}`;
        }
        if (subcategory) {
            url += `&subcategory=${subcategory}`;
        }

        // Continue building URL for other filters...

        const { data } = await axios.get(url);
        dispatch({ type: ALL_PRODUCTS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: ALL_PRODUCTS_FAIL, payload: error.response.data.message });
    }
};

    //get product under categorey
    

// Fetch products based on selected category directly from backend
export const getProductsByCategory = async (category) => {
    try {
        // Use the correct endpoint from your backend (`/productscategory`)
        const { data } = await axios.get(`/api/v1/productscategory`, {
            params: {
                category: category || '', // Pass category as query parameter (optional)
            },
        });

        return data; // Return the fetched data
    } catch (error) {
        throw new Error(
            error.response && error.response.data.message
                ? error.response.data.message
                : error.message
        );
    }
};

//http://localhost:4000/api/v1/getsubcategory?category=Vegetables&subcategory=Fruits
export const getSubCategories = (category, subcategory) => async (dispatch) => {
    try {
        dispatch({ type: SUBCATEGORIES_REQUEST });

        const { data } = await axios.get(`/api/v1/products?category=${category}&subcategory=${subcategory}`);

        dispatch({
            type: SUBCATEGORIES_SUCCESS,
            payload: data
        });
    } catch (error) {
        dispatch({
            type: SUBCATEGORIES_FAIL,
            payload: error.response.data.message,
        });
    }
};



    export const getProductSuggestions = (query) => async (dispatch) => {
        try {
            dispatch({ type: PRODUCT_SUGGESTIONS_REQUEST });
    
            const { data } = await axios.get(`/api/v1/suggestions?query=${query}`);
    
            dispatch({
                type: PRODUCT_SUGGESTIONS_SUCCESS,
                payload: data,
            });
        } catch (error) {
            dispatch({
                type: PRODUCT_SUGGESTIONS_FAIL,
                payload: error.response && error.response.data.message
                    ? error.response.data.message
                    : error.message,
            });
        }
    }

// Get All Products Of Same Category
export const getSimilarProducts = (category) => async (dispatch) => {
    try {
        dispatch({ type: ALL_PRODUCTS_REQUEST });
        

        const { data } = await axios.get(`/api/v1/products?category=${category}`);

        dispatch({
            type: ALL_PRODUCTS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: ALL_PRODUCTS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get Product Details
export const getProductDetails = (id) => async (dispatch) => {
    try {
        dispatch({ type: PRODUCT_DETAILS_REQUEST });

        const { data } = await axios.get(`/api/v1/product/${id}`);

        dispatch({
            type: PRODUCT_DETAILS_SUCCESS,
            payload: data.product,
        });
    } catch (error) {
        dispatch({
            type: PRODUCT_DETAILS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// New/Update Review
export const newReview = (reviewData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_REVIEW_REQUEST });
        const config = { header: { "Content-Type": "application/json" } }
        const { data } = await axios.put("/api/v1/review", reviewData, config);

        dispatch({
            type: NEW_REVIEW_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: NEW_REVIEW_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Get All Products ---PRODUCT SLIDER
export const getSliderProducts = () => async (dispatch) => {
    try {
        dispatch({ type: SLIDER_PRODUCTS_REQUEST });

        const { data } = await axios.get('/api/v1/products/all');

        dispatch({
            type: SLIDER_PRODUCTS_SUCCESS,
            payload: data.products,
        });
    } catch (error) {
        dispatch({
            type: SLIDER_PRODUCTS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// Get All Products ---ADMIN
export const getAdminProducts = () => async (dispatch) => {
    try {
        dispatch({ type: ADMIN_PRODUCTS_REQUEST });

        const { data } = await axios.get('/api/v1/admin/products');

        dispatch({
            type: ADMIN_PRODUCTS_SUCCESS,
            payload: data.products,
        });
    } catch (error) {
        dispatch({
            type: ADMIN_PRODUCTS_FAIL,
            payload: error.response.data.message,
        });
    }
};

// New Product ---ADMIN
export const createProduct = (productData) => async (dispatch) => {
    try {
        dispatch({ type: NEW_PRODUCT_REQUEST });
        const config = { header: { "Content-Type": "application/json" } }
        const { data } = await axios.post("/api/v1/admin/product/new", productData, config);

        dispatch({
            type: NEW_PRODUCT_SUCCESS,
            payload: data,
        });
        
    } catch (error) {
        dispatch({
            type: NEW_PRODUCT_FAIL,
            payload: error.response.data.message,
        });
        
    }
}

// Update Product ---ADMIN

export const updateProduct = (id, productData) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PRODUCT_REQUEST });

        // Set up the configuration for the request
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        // Send PUT request to update the product
        const { data } = await axios.put(`/api/v1/admin/product/${id}`, productData, config);

        // Dispatch success action
        dispatch({
            type: UPDATE_PRODUCT_SUCCESS,
            payload: data.product, // Include product data in payload if you need to use it
        });
    } catch (error) {
        // Dispatch failure action
        dispatch({
            type: UPDATE_PRODUCT_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
    }
};
// Delete Product ---ADMIN
export const deleteProduct = (id) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_PRODUCT_REQUEST });
        const { data } = await axios.delete(`/api/v1/admin/product/${id}`);

        dispatch({
            type: DELETE_PRODUCT_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: DELETE_PRODUCT_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Get Product Reviews ---ADMIN

export const getAllReviews = () => async (dispatch) => {
    try {
        dispatch({ type: ALL_REVIEWS_REQUEST });

        const { data } = await axios.get('/api/v1/products/reviews');

        if (data.success) {
            dispatch({
                type: ALL_REVIEWS_SUCCESS,
                payload: data.products, // Assuming the API returns an array of products with reviews
            });
        } else {
            dispatch({
                type: ALL_REVIEWS_FAIL,
                payload: data.message,
            });
        }
    } catch (error) {
        dispatch({
            type: ALL_REVIEWS_FAIL,
            payload: error.response?.data?.message || error.message,
        });
    }
};

// Delete Product Review ---ADMIN
export const deleteReview = (reviewId, productId) => async (dispatch) => {
    try {
        dispatch({ type: DELETE_REVIEW_REQUEST });
        const { data } = await axios.delete(`/api/v1/admin/reviews?id=${reviewId}&productId=${productId}`);

        dispatch({
            type: DELETE_REVIEW_SUCCESS,
            payload: data.success,
        });
    } catch (error) {
        dispatch({
            type: DELETE_REVIEW_FAIL,
            payload: error.response.data.message,
        });
    }
}

// Clear All Errors
export const clearErrors = () => (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
}