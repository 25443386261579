import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Loader from '../Layouts/Loader';
import MinCategory from '../Layouts/MinCategory';
import MetaData from '../Layouts/MetaData';
import { getUserDetails } from '../../../src/actions/userAction'; // Adjust the import path accordingly

const Account = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, loading, isAuthenticated } = useSelector(state => state.user);

    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/login");
        } else {
            dispatch(getUserDetails(user._id)); // Assuming you have user ID in the user object
        }
    }, [isAuthenticated, navigate, dispatch, user._id]);

    const getLastName = () => {
        const nameArray = user.name.split(" ");
        return nameArray[nameArray.length - 1];
    };

    return (
        <>
            <MetaData title="My Profile" />

            {loading ? (
                <Loader />
            ) : (
                <>
                    <MinCategory />
                    <main className="w-full mt-6 sm:mt-0">
                        <div className="flex flex-col sm:flex-row sm:w-11/12 m-auto mb-7">
                            <Sidebar activeTab={"profile"} />
                            <div className="flex-1 overflow-hidden shadow bg-white rounded-lg">
                                <div className="flex flex-col gap-12 m-4 sm:mx-8 sm:my-6">
                                    <div className="flex flex-col gap-5 items-start">
                                        <span className="font-medium text-lg">
                                            Personal Information
                                            <Link to="/account/update" className="text-sm text-primary-blue font-medium ml-8 cursor-pointer">Edit</Link>
                                        </span>

                                        <div className="flex flex-col gap-6 m-4 sm:m-8">
                                            <div className="flex flex-col gap-5 items-start">
                                                <div className="flex flex-col sm:flex-row items-center gap-3">
                                                    <div className="flex flex-col gap-0.5 w-full sm:w-64 px-3 py-1.5 rounded-sm border bg-gray-100">
                                                        <label className="text-xs text-gray-500">First Name</label>
                                                        <input type="text" value={user.name.split(" ", 1)} className="text-sm outline-none border-none text-gray-500" disabled />
                                                    </div>
                                                    <div className="flex flex-col gap-0.5 w-full sm:w-64 px-3 py-1.5 rounded-sm border bg-gray-100">
                                                        <label className="text-xs text-gray-500">Last Name</label>
                                                        <input type="text" value={getLastName()} className="text-sm outline-none border-none text-gray-500" disabled />
                                                    </div>
                                                </div>

                                                <div className="flex flex-col gap-2">
                                                    <h2 className="text-sm">Your Gender</h2>
                                                    <div className="flex items-center gap-4">
                                                        <div className="flex items-center gap-2 inputs text-gray-500">
                                                            <input type="radio" name="gender" checked={user.gender === "male"} id="male" className="h-4 w-4" disabled />
                                                            <label htmlFor="male">Male</label>
                                                        </div>
                                                        <div className="flex items-center gap-2 inputs text-gray-500">
                                                            <input type="radio" name="gender" checked={user.gender === "female"} id="female" className="h-4 w-4" disabled />
                                                            <label htmlFor="female">Female</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex flex-col gap-5 items-start">
                                                <span className="font-medium text-lg">
                                                    Email Address
                                                    <Link to="/account/update" className="text-sm text-primary-blue font-medium ml-3 sm:ml-8 cursor-pointer">Edit</Link>
                                                    <Link to="/password/update" className="text-sm text-primary-blue font-medium ml-3 sm:ml-8">Change Password</Link>
                                                </span>
                                                <div className="flex items-center gap-3">
                                                    <div className="flex flex-col gap-0.5 w-full sm:w-64 px-3 py-1.5 rounded-sm border bg-gray-100">
                                                        <label className="text-xs text-gray-500">Email Address</label>
                                                        <input type="email" value={user.email} className="text-sm outline-none border-none text-gray-500" disabled />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex flex-col gap-5 items-start">
                                                <span className="font-medium text-lg">
                                                    Mobile Number
                                                    <span className="text-sm text-primary-blue font-medium ml-3 sm:ml-8 cursor-pointer" id="mobEditBtn">Edit</span>
                                                </span>
                                                <div className="flex items-center gap-3">
                                                    <div className="flex flex-col gap-0.5 w-full sm:w-64 px-3 py-1.5 rounded-sm border bg-gray-100">
                                                        <label className="text-xs text-gray-500">Mobile Number</label>
                                                        <input type="tel" value={user.mobilenumber} className="text-sm outline-none border-none text-gray-500" disabled />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex flex-col gap-5 items-start">
                                            <span className="font-medium text-lg">Profile Picture</span>
                                            {user.image && user.image.url && (
                                                <div className="flex items-center gap-3">
                                                    <img src={user.image.url} alt="Profile" className="w-32 h-32 object-cover rounded-full border" />
                                                    <Link to="/account/update" className="text-sm text-primary-blue font-medium ml-3 sm:ml-8 cursor-pointer">Edit</Link>
                                                </div>
                                            )}
                                        </div>

                                            <div className="flex flex-col gap-4 mt-4">
                                                <span className="font-medium text-lg mb-2">FAQS</span>
                                                <h4 className="text-sm font-medium">What happens when I update my email address (or mobile number)?</h4>
                                                <p className="text-sm">Your login email id (or mobile number) changes, likewise. You'll receive all your account related communication on your updated email address (or mobile number).</p>

                                                <h4 className="text-sm font-medium">When will my Grafin account be updated with the new email address (or mobile number)?</h4>
                                                <p className="text-sm">It happens as soon as you confirm the verification code sent to your email (or mobile) and save the changes.</p>

                                                <h4 className="text-sm font-medium">What happens to my existing account when I update my email address (or mobile number)?</h4>
                                                <p className="text-sm">Updating your email address (or mobile number) doesn't invalidate your account. Your account remains fully functional. You'll continue seeing your Order history, saved information and personal details.</p>

                                                <h4 className="text-sm font-medium">Does my Seller account get affected when I update my email address?</h4>
                                                <p className="text-sm">Grafin has a 'single sign-on' policy. Any changes will reflect in your Seller account also.</p>
                                            </div>

                                            <Link className="text-sm text-primary-blue font-medium" to="/">Deactivate Account</Link>
                                        </div>

                                        
                                    </div>
                                </div>
                                {/* <img draggable="false" className="w-full object-contain" src="https://static-assets-web.flixcart.com/www/linchpin/fk-cp-zion/img/myProfileFooter_4e9fe2.png" alt="footer" /> */}
                            </div>
                        </div>
                    </main>
                </>
            )}
        </>
    );
};

export default Account;
