import SearchIcon from '@mui/icons-material/Search';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getProductSuggestions } from '../../../actions/productAction';

const Searchbar = () => {
    const [keyword, setKeyword] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { suggestions: reduxSuggestions } = useSelector((state) => state.productSuggestions);

    useEffect(() => {
        setSuggestions(reduxSuggestions);
    }, [reduxSuggestions]);

    const handleInputChange = (e) => {
        const value = e.target.value;
        console.log("value",value);
        
        setKeyword(value);

        if (value.trim()) {
            console.log("value from if ",value);
            
            dispatch(getProductSuggestions(value));
        } else {
            setSuggestions([]);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (keyword.trim()) {
            navigate(`/products/${keyword}`);
        } else {
            navigate('/products');
        }
        setSuggestions([]);
    };

    const handleSuggestionClick = (suggestion) => {
        setKeyword(suggestion);
        setSuggestions([]);
        navigate(`/products/${suggestion}`);
    };

    return (
        <div className="relative w-full sm:w-9/12">
            <form onSubmit={handleSubmit}  className="w-full  px-1 sm:px-4 py-1.5 flex justify-between items-center shadow-md bg-white rounded-full overflow-hidden">
                <input
                    value={keyword}
                    onChange={handleInputChange}
                    className="text-sm flex-1 outline-none border-none placeholder-gray-500 px-2"
                    type="text"
                    placeholder="Search for products, brands and more"
                />
                <button type="submit" className="text-primary-blue px-2">
                    <SearchIcon style={{ color: '#484a4a' }} />
                </button>
            </form>
            {suggestions.length > 0 && (
                <ul className="absolute z-10 w-full bg-white shadow-md rounded-b-lg mt-1">
                    {suggestions.map((suggestion) => (
                        <li
                            key={suggestion._id}
                            className="px-4 py-2 cursor-pointer hover:bg-gray-200"
                            onClick={() => handleSuggestionClick(suggestion.name)}
                        >
                            {suggestion.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default Searchbar;
