import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Searchbar from '../Header/Searchbar';
import logo from '../../../assets/images/logo.png';
import PrimaryDropDownMenu from './PrimaryDropDownMenu';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown"; 
import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const Header = () => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { cartItems } = useSelector(state => state.cart);
  const { wishlistItems } = useSelector(state => state.wishlist);

  const [togglePrimaryDropDown, setTogglePrimaryDropDown] = useState(false);
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);
  
  // Create refs for mobile menu and dropdown
  const mobileMenuRef = useRef(null);
  const primaryDropdownRef = useRef(null);

  const handleToggleMobileMenu = () => {
    setToggleMobileMenu(!toggleMobileMenu);
    setTogglePrimaryDropDown(false); // Ensure dropdown is closed when toggling menu
  };

  const handleClickOutside = (event) => {
    // Check if click is outside mobile menu and primary dropdown
    if (
      mobileMenuRef.current && !mobileMenuRef.current.contains(event.target) &&
      primaryDropdownRef.current && !primaryDropdownRef.current.contains(event.target)
    ) {
      setToggleMobileMenu(false); // Close mobile menu
      setTogglePrimaryDropDown(false); // Close primary dropdown
    }
  };

  const handleLinkClick = () => {
    setToggleMobileMenu(false); // Close the mobile menu
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header className="fixed top-0 py-2.5 w-full z-10 " style={{backgroundColor:"#a5447e"}}>
      <div className="w-full sm:w-9/12 px-1 sm:px-4 m-auto flex justify-between items-center relative">
        <div className="flex items-center flex-1">
          <Link className="h-7 mr-4" to="/">
            <img
              draggable="false"
              className="h-full w-full object-contain"
              src={logo}
              alt="Grafin Logo"
            />
          </Link>
          <Searchbar />
        </div>

        <div className="flex items-center justify-between ml-1 sm:ml-0 gap-0.5 sm:gap-7 relative">
          <div className="flex sm:hidden items-center text-white font-medium cursor-pointer" onClick={handleToggleMobileMenu}>
            {toggleMobileMenu ? <CloseIcon /> : <MenuIcon />}
          </div>

          {isAuthenticated === false ? (
            <Link to="/login" className="hidden sm:flex items-center text-white font-medium gap-2 relative group">
              <AccountCircleIcon />
              <span className="absolute -left-2 top-4 ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-xs bg-gray-800 text-white p-1 rounded">Login</span>
            </Link>
          ) : (
            <span 
              className="hidden sm:flex userDropDown items-center text-white font-medium gap-1 cursor-pointer group" 
              onClick={() => setTogglePrimaryDropDown(!togglePrimaryDropDown)}
              ref={primaryDropdownRef} // Add ref here
            >
              <AccountCircleIcon />
              <span className="absolute -left-2 top-4 ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-xs bg-gray-800 text-white p-1 rounded">Profile</span>
            </span>
          )}

          {togglePrimaryDropDown && !toggleMobileMenu && (
            <PrimaryDropDownMenu
              setTogglePrimaryDropDown={setTogglePrimaryDropDown}
              setToggleMobileMenu={setToggleMobileMenu}
              user={user}
            />
          )}

          <Link to="/wishlist" className="hidden sm:flex items-center text-white font-medium gap-2 relative group">
            <span><FavoriteIcon /></span>
            {wishlistItems.length > 0 &&
              <div className="w-5 h-5 p-2 bg-red-500 text-xs rounded-full absolute -top-2 left-3 flex justify-center items-center border">
                {wishlistItems.length}
              </div>
            }
            <span className="absolute  -left-2 top-4 ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-xs bg-gray-800 text-white p-1 rounded">Wishlist</span>
          </Link>

          <Link to="/cart" className="hidden sm:flex items-center text-white font-medium gap-2 relative group">
            <span><ShoppingCartIcon /></span>
            {cartItems.length > 0 &&
              <div className="w-5 h-5 p-2 bg-red-500 text-xs rounded-full absolute -top-2 left-3 flex justify-center items-center border">
                {cartItems.length}
              </div>
            }
            <span className="absolute -left-2 top-4 ml-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 text-xs bg-gray-800 text-white p-1 rounded">Cart</span>
          </Link>
        </div>
      </div>

      {toggleMobileMenu && (
        <div ref={mobileMenuRef} className="sm:hidden w-full p-4 flex flex-col gap-4" style={{backgroundColor:"#657c89"}}>
          {isAuthenticated === false ? (
            <Link
              to="/login"
              className="text-primary-blue bg-white border font-medium rounded-sm cursor-pointer py-2 text-center"
              style={{ color: '#484a4a' }}
              onClick={handleLinkClick} // Close menu on click
            >
              Login
            </Link>
          ) : (
            <div
              className="flex items-center text-white font-medium gap-1 cursor-pointer"
              onClick={() => {
                setTogglePrimaryDropDown(!togglePrimaryDropDown);
                // setToggleMobileMenu(false); // Close menu when toggling dropdown
              }}
            >
              <AccountCircleIcon />
              <span>Profile</span>
              <KeyboardArrowDown />
            </div>
          )}

          {togglePrimaryDropDown && (
            <PrimaryDropDownMenu setTogglePrimaryDropDown={setTogglePrimaryDropDown} setToggleMobileMenu={setToggleMobileMenu} user={user} />
          )}

          <Link
            to="/wishlist"
            className="flex items-center text-white font-medium gap-2 relative group"
            onClick={handleLinkClick} // Close menu on click
          >
            <span><FavoriteIcon /></span><span>Wishlist</span>
            {wishlistItems.length > 0 && (
              <div className="w-5 h-5 p-2 bg-red-500 text-xs rounded-full absolute -top-2 left-3 flex justify-center items-center border">
                {wishlistItems.length}
              </div>
            )}
          </Link>

          <Link
            to="/cart"
            className="flex items-center text-white font-medium gap-2 relative group"
            onClick={handleLinkClick} // Close menu on click
          >
            <span><ShoppingCartIcon /></span><span>Cart</span>
            {cartItems.length > 0 && (
              <div className="w-5 h-5 p-2 bg-red-500 text-xs rounded-full absolute -top-2 left-3 flex justify-center items-center border">
                {cartItems.length}
              </div>
            )}
          </Link>
        </div>
      )}
    </header>
  );
};

export default Header;
