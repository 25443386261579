// import React from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import EqualizerIcon from '@mui/icons-material/Equalizer';
// import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
// import InventoryIcon from '@mui/icons-material/Inventory';
// import GroupIcon from '@mui/icons-material/Group';
// import ReviewsIcon from '@mui/icons-material/Reviews';
// import AddBoxIcon from '@mui/icons-material/AddBox';
// import LogoutIcon from '@mui/icons-material/Logout';
// import AccountBoxIcon from '@mui/icons-material/AccountBox';
// import CloseIcon from '@mui/icons-material/Close';
// import { useDispatch, useSelector } from 'react-redux';
// import { useSnackbar } from 'notistack';
// import { logoutUser } from '../../../actions/userAction';
// import './Sidebar.css';

// const navMenu = [
//     {
//         icon: <EqualizerIcon />,
//         label: "Dashboard",
//         ref: "/admin/dashboard",
//     },
//     {
//         icon: <ShoppingBagIcon />,
//         label: "Orders",
//         ref: "/admin/orders",
//     },
//     {
//         icon: <InventoryIcon />,
//         label: "Products",
//         ref: "/admin/products",
//     },
//     {
//         icon: <AddBoxIcon />,
//         label: "Add Product",
//         ref: "/admin/new_product",
//     },
//     {
//         icon: <AddBoxIcon />,
//         label: "Add Seller",
//         ref: "/admin/sellers",
//     },
//     {
//         icon: <GroupIcon />,
//         label: "Sellers",
//         ref: "/admin/sellertable",
//     },
//     {
//         icon: <GroupIcon />,
//         label: "DeliveryGuyTable",
//         ref: "/admin/DeliveryGuyTable",
//     },
//     {
//         icon: <GroupIcon />,
//         label: "Users",
//         ref: "/admin/users",
//     },
//     {
//         icon: <ReviewsIcon />,
//         label: "Reviews",
//         ref: "/admin/reviews",
//     },
//     {
//         icon: <AccountBoxIcon />,
//         label: "My Profile",
//         ref: "/account",
//     },
//     {
//         icon: <LogoutIcon />,
//         label: "Logout",
//     },
// ];

// const Sidebar = ({ activeTab, setToggleSidebar }) => {
//     const dispatch = useDispatch();
//     const navigate = useNavigate();
//     const { enqueueSnackbar } = useSnackbar();

//     const { user } = useSelector((state) => state.user);

//     const handleLogout = () => {
//         dispatch(logoutUser());
//         enqueueSnackbar("Logout Successfully", { variant: "success" });
//         navigate("/login");
//     };

//     const handleNavClick = () => {
//         // Close sidebar on navigation link click
//         if (window.innerWidth < 600) {
//             setToggleSidebar(false);
//         }
//     };

//     return (
//         <aside className="sidebar z-10 sm:z-0 fixed left-0 pb-14 max-h-screen w-3/4 sm:w-1/5 text-white overflow-x-hidden border-r"style={{backgroundColor:"#f9ecf4"}}>
//             <div className="flex items-center gap-3  p-2 rounded-lg shadow-lg my-4 mx-3.5"style={{backgroundColor:"#d9caca"}}>
//                 <div className="flex flex-col gap-0"style={{color:'black'}}>
//                     <span className="font-medium text-lg">{user.name}</span>
//                     <span className="text-sm">{user.email}</span>
//                 </div>
//                 <button onClick={() => setToggleSidebar(false)} className="sm:hidden bg-gray-800 ml-auto rounded-full w-10 h-10 flex items-center justify-center">
//                     <CloseIcon />
//                 </button>
//             </div>

//             <div className="flex flex-col w-full gap-0 my-8">
//                 {navMenu.map((item, index) => {
//                     const { icon, label, ref } = item;
//                     return (
//                         <React.Fragment key={label}>
//                             {label === "Logout" ? (
//                                 <button onClick={handleLogout} className="hover:bg-purple-200 flex gap-3 items-center py-3 px-4 font-medium"style={{color:'black'}}>
//                                     <span>{icon}</span>
//                                     <span>{label}</span>
//                                 </button>
//                             ) : (
//                                 <Link
//                                     to={ref}
//                                     onClick={handleNavClick} // Close sidebar when clicked
//                                     className={`${activeTab === index ? "bg-custom-pink" : "hover:bg-purple-200"} flex gap-3 items-center py-3 px-4 font-medium`}
//                                     style={{color:"black"}}
//                                     >
//                                     <span>{icon}</span>
//                                     <span>{label}</span>
//                                 </Link>
//                             )}
//                         </React.Fragment>
//                     );
//                 })}
//             </div>
//         </aside>
//     );
// };

// export default Sidebar;
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import InventoryIcon from '@mui/icons-material/Inventory';
import GroupIcon from '@mui/icons-material/Group';
import ReviewsIcon from '@mui/icons-material/Reviews';
import AddBoxIcon from '@mui/icons-material/AddBox';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { logoutUser } from '../../../actions/userAction';
import './Sidebar.css';

const navMenu = [
    { icon: <EqualizerIcon />, label: "Dashboard", ref: "/admin/dashboard" },
    { icon: <ShoppingBagIcon />, label: "Orders", ref: "/admin/orders" },
    { icon: <InventoryIcon />, label: "Products", ref: "/admin/products" },
    { icon: <AddBoxIcon />, label: "Add Product", ref: "/admin/new_product" },
    { icon: <AddBoxIcon />, label: "Add Seller", ref: "/admin/sellers" },
    { icon: <GroupIcon />, label: "Sellers", ref: "/admin/sellertable" },
    { icon: <GroupIcon />, label: "DeliveryGuyTable", ref: "/admin/DeliveryGuyTable" },
    { icon: <GroupIcon />, label: "Users", ref: "/admin/users" },
    { icon: <ReviewsIcon />, label: "Reviews", ref: "/admin/reviews" },
    { icon: <AccountBoxIcon />, label: "My Profile", ref: "/account" },
    { icon: <LogoutIcon />, label: "Logout" },
];

const Sidebar = ({ activeTab, setToggleSidebar }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { user } = useSelector((state) => state.user);

    const handleLogout = () => {
        dispatch(logoutUser());
        enqueueSnackbar("Logout Successfully", { variant: "success" });
        navigate("/login");
        // handleNavClick();
    };

    const handleNavClick = () => {


        if (window.innerWidth < 600) {
            setToggleSidebar(false); // Close sidebar on mobile when a link is clicked
        }
    };;



    return (
        <aside className="sidebar z-10 sm:z-0 fixed left-0 pb-14 max-h-screen w-3/4 sm:w-1/5  text-white overflow-x-hidden border-r" style={{ backgroundColor: "#f9ecf4", height: "100%" }}>

            {/* className={`sidebar fixed left-0 pb-14 max-h-screen w-2/4 sm:w-1/5 text-white overflow-x-hidden border-r z-10 sm:z-0 transform ${ */}
            {/* //         toggleSidebar ? "translate-x-full" : "translate-x-0"
    //     } md:translate-x-0 transition-transform duration-300 ease-in-out`}
    //     style={{
    //         backgroundColor: "#f9ecf4",
    //     }} */}

            {/* Profile and Close Button */}
            <div className="flex items-center gap-3 p-4 rounded-lg shadow-lg mx-4">
                <div className="flex flex-col" style={{ color: "black" }}>
                    <span className="font-medium text-lg">{user.name}</span>
                    <span className="text-sm">{user.email}</span>
                </div>
                <button
                    onClick={() => {

                        setToggleSidebar(false)
                    }} // Close the sidebar
                    className="sm:hidden bg-gray-800 ml-auto rounded-full w-10 h-10 flex items-center justify-center"
                >
                    <CloseIcon />
                </button>
            </div>

            {/* Navigation Links */}
            <div className="flex flex-col w-full mt-4">
                {navMenu.map((item, index) => {
                    const { icon, label, ref } = item;
                    return (
                        <React.Fragment key={label}>
                            {label === "Logout" ? (
                                <button
                                    onClick={() => {
                                        handleLogout();
                                        // setToggleSidebar(false); 
                                        // Close the sidebar
                                    }}
                                    className="flex gap-3 items-center py-3 px-4 w-full hover:bg-purple-200 text-black font-medium"
                                >
                                    <span>{icon}</span>
                                    <span>{label}</span>
                                </button>
                            ) : (
                                <Link
                                    to={ref}
                                    onClick={() => {
                                        handleNavClick();
                                        // setToggleSidebar(false);
                                        // Close the sidebar
                                    }}
                                    className={`${activeTab === index
                                            ? "bg-custom-pink " // Add shadow class when active
                                            : "hover:bg-purple-200"
                                        } flex gap-3 items-center py-3 px-4 font-medium rounded-md`} // Ensure rounded corners for a better shadow effect
                                    style={{ color: "black" }}
                                >
                                    <span>{icon}</span>
                                    <span>{label}</span>
                                </Link>

                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        </aside>

    );
};

export default Sidebar;
