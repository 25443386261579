


import { useEffect, useState } from 'react';
import Sidebar from './Sidebar/Sidebar';
import MenuIcon from '@mui/icons-material/Menu';

const Dashboard = ({ activeTab, children }) => {

    const [onMobile, setOnMobile] = useState(window.innerWidth < 600);
    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [onTablet, setOnTablet] = useState(false);
    const [onDesktop, setOnDesktop] = useState(true);

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;

            // Set screen size breakpoints
            setOnMobile(width <= 480); // Mobile
            setOnTablet(width > 480 && width <= 1024); // Tablet
            setOnDesktop(width > 1024); // Desktop
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call once to set initial state

        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <>
           <main
            className="flex min-h-screen mt-14"
            style={{
                width: '100%',
                overflow: 'hidden',
            }}
        >
            {/* Sidebar for Desktop */}
            {!onMobile && (
                <Sidebar activeTab={activeTab} />
            )}

            {/* Sidebar for Mobile - Toggles with button */}
            {toggleSidebar && (
                <Sidebar activeTab={activeTab} setToggleSidebar={setToggleSidebar} />
            )}

<div
            style={{
                width: '100%', // Full width
                marginLeft: onMobile ? '0' : onTablet ? '200px' : '345px', // Sidebar margin for tablet and desktop
                minHeight: '100vh',
                overflow: 'hidden',
                padding: onMobile ? '8px' : onTablet ? '16px' : '32px', // Adjust padding for mobile, tablet, desktop
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: onMobile ? '16px' : '24px', // Gap size for mobile vs tablet/desktop
                    padding: onMobile ? '8px' : '16px', // Adjust padding for mobile vs tablet/desktop
                    paddingBottom: '24px',
                }}
            >
                {/* Sidebar Toggle Button for Mobile */}
                <button
                    onClick={() => setToggleSidebar(true)}
                    style={{
                        display: onMobile ? 'flex' : 'none', // Hide the button on tablet/desktop
                        backgroundColor: '#4a5568',
                        width: '40px',
                        height: '40px',
                        borderRadius: '50%',
                        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                        color: 'white',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <MenuIcon />
                </button>

                {/* Children Content */}
                {children}
            </div>
        </div>
        </main>
        </>
    );
};

export default Dashboard;

