import StarIcon from '@mui/icons-material/Star';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { Link } from 'react-router-dom';
import { getDiscount } from '../../utils/functions';
import { useDispatch, useSelector } from 'react-redux';
import { addToWishlist, removeFromWishlist } from '../../actions/wishlistAction';
import { useSnackbar } from 'notistack';

const Product = ({ _id, name, images, ratings, numOfReviews, price, cuttedPrice }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { wishlistItems } = useSelector((state) => state.wishlist);

    const itemInWishlist = wishlistItems.some((i) => i.product === _id);

    const addToWishlistHandler = () => {
        if (itemInWishlist) {
            dispatch(removeFromWishlist(_id));
            enqueueSnackbar("Removed From Wishlist", { variant: "success" });
        } else {
            dispatch(addToWishlist(_id));
            enqueueSnackbar("Added To Wishlist", { variant: "success" });
        }
    };

    return (
        <div className="flex flex-col items-center p-4 relative hover:shadow-lg rounded-sm w-full sm:w-64">
            {/* Product Image */}
            <Link to={`/product/${_id}`} className="group">
                <div className="w-40 h-48 mx-auto">
                    <img
                        draggable="false"
                        className="w-full h-full "

                        // className="w-full h-full object-contain "
                        src={images && images[0].url}
                        alt={name}
                    />
                </div>
            </Link>

            {/* Product Details */}
            <div className="flex flex-col items-center mt-4 gap-2 text-center">
                <Link to={`/product/${_id}`} className="group">
                    <h2 className="text-sm group-hover:text-primary-blue">
                        {name.length > 85 ? `${name.substring(0, 85)}...` : name}
                    </h2>
                </Link>

                {/* Rating and Reviews */}
                <div className="flex items-center gap-2">
                    <span className="text-xs bg-primary-green text-white rounded-sm px-2 py-1 flex items-center gap-1">
                        {ratings.toFixed(1)} <StarIcon sx={{ fontSize: '14px' }} />
                    </span>
                    <span className="text-sm text-gray-500">({numOfReviews})</span>
                </div>

                {/* Pricing */}
                <div className="flex items-center gap-1 text-md font-medium">
                    <span>₹{price.toLocaleString()}</span>
                    <span className="text-gray-500 line-through text-xs">
                        ₹{cuttedPrice.toLocaleString()}
                    </span>
                    <span className="text-xs text-primary-green">
                        {getDiscount(price, cuttedPrice)}% off
                    </span>
                </div>
            </div>

            {/* Wishlist Icon */}
            <span
                onClick={addToWishlistHandler}
                className={`${
                    itemInWishlist ? 'text-red-500' : 'text-gray-300 hover:text-red-500'
                } absolute top-4 right-4 cursor-pointer`}
            >
                <FavoriteIcon sx={{ fontSize: '18px' }} />
            </span>
        </div>
    );
};

export default Product;
