import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Banner.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import home from '../../../assets/images/Banners/home.png';
import makeup from '../../../assets/images/Banners/makeup.jpg';
import mobile from '../../../assets/images/Banners/mobile.jpg';
import coustume from '../../../assets/images/Banners/coustume.jpg';
import kitchen from '../../../assets/images/Banners/kitchen.jpg';
import footwear from '../../../assets/images/Banners/footwear.jpg';

const isMobile = window.innerWidth < 321;
export const PreviousBtn = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick} style={{ fontSize: 'small' }}>
      <ArrowBackIosIcon style={{ fontSize: isMobile ? '1rem' : '2.5rem' }} /> {/* Adjust size here */}
    </div>
  );
};

export const NextBtn = ({ className, onClick }) => {
  return (
    <div className={className} onClick={onClick} style={{ fontSize: 'small' }}>
      <ArrowForwardIosIcon style={{ fontSize: isMobile ? '1rem' : '2.5rem' }}/> {/* Adjust size here */}
    </div>
  );
};


const Banner = () => {

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
  };

  const banners = [home,makeup,mobile,coustume,kitchen,footwear];

  return (
    <>
    <section className=" w-full rounded-sm shadow relative overflow-hidden">
      <Slider {...settings}>
        {banners.map((el, i) => (
         <img
         draggable="false"
         className="w-full h-[21vh] sm:h-full md:h-full lg:h-full object-cover"
         src={el}
         alt="banner"
         key={i}
       />
       
        ))}
      </Slider>
    </section>
  </>
  );
};

export default Banner;
