import Product from './Product';
import Slider from 'react-slick';
import { NextBtn, PreviousBtn } from '../Banner/Banner';
import { Link } from 'react-router-dom';
import { offerProducts } from '../../../utils/constants';
import { getRandomProducts } from '../../../utils/functions';

export const settings = {
    dots: false,
    infinite: true,       // Make slider loop back to start
    speed: 1000,
    slidesToShow: 5,     // Show 5 slides on desktop
    slidesToScroll: 5,    // Scroll 5 slides at a time
    initialSlide: 1,
    swipe: true,          // Allow manual swipe
    prevArrow: <PreviousBtn />,  // Add manual navigation buttons
    nextArrow: <NextBtn />,      // Add manual navigation buttons
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,  // Show 3 slides on medium screens
                slidesToScroll: 3
            }
        },
        {
            breakpoint: 768, // Adjust breakpoint as needed for mobile devices
            settings: {
                slidesToShow: 2,  // Show 2 slides on mobile devices
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,  // Optionally, show 1 slide on very small devices
                slidesToScroll: 1
            }
        }
    ]
};

const DealSlider = ({ title }) => {
    return (
        <section className=" w-full shadow overflow-hidden" style={{backgroundColor:"#b19aad59"}}>
            {/* <!-- header --> */}
            <div className="flex px-6 py-3 justify-between items-center" style={{backgroundColor:"#ddb3dd7a"}}>
                <h1 className="text-xl font-medium">{title}</h1>
                
                <Link to="/products" className="bg-primary-blue text-xs font-medium text-white px-5 py-2.5  shadow-lg" style={{backgroundColor:'#484a4a',borderRadius:"1.125rem"}}>VIEW ALL</Link>
            </div>
            <hr />
            {/* <!-- header --> */}

            <Slider {...settings}>
                {getRandomProducts(offerProducts, 12).map((item, i) => (
                    <Product {...item} key={i} />
                ))}
            </Slider>
        </section>
    );
};

export default DealSlider;
